import { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { getProperty, useLocale } from "@gh/shared";

export const useActivitiesNext = (path) => {
  const refActivities = useRef(null);
  const refLocale = useRef(null);
  const { locale: currentLocale } = useLocale();
  const key = "allMarkdownRemark.edges";
  const results = useStaticQuery(query) || {};
  const edges = getProperty(results, key) || [];

  if (refActivities.current === null || refLocale.current !== currentLocale) {
    const activities = edges
      .filter((edge) => currentLocale === getProperty(edge, "node.frontmatter.locale"))
      .map((edge) => ({
        ...getProperty(edge, "node.frontmatter.meta"),
        path: getProperty(edge, "node.frontmatter.path"),
      }));

    const currentIndex = activities.findIndex((current) => getProperty(current, "path") === path);
    const nextActivity =
      currentIndex >= 0 && currentIndex < activities.length - 1
        ? activities[currentIndex + 1]
        : null;

    refActivities.current = nextActivity;
  }

  refLocale.current = currentLocale;

  return refActivities.current;
};

const query = graphql`
  query useActivitiesNextQuery {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          type: { eq: "page" },
          view: { eq: "activity" },
          activity: {
            hidden: { ne: true }
          }
        }
      }
      sort: {
        fields: frontmatter___activity___date, order: DESC
      }
    ) {
      edges {
        node {
          frontmatter {
            locale
            path
            meta {
              title
              description
            }
          }
        }
      }
    }
  }
`;
