// extracted by mini-css-extract-plugin
export var black = "section-module--black--1eZts";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var center = "section-module--center--jM3rw";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var contentVisibility = "section-module--content-visibility--LTZrZ";
export var gradient = "section-module--gradient--VqATW";
export var isFullHeight = "section-module--is-full-height--NNjre";
export var left = "section-module--left--h1x5r";
export var lg = "section-module--lg--z7saf";
export var md = "section-module--md--Fws0P";
export var noPaddingBottom = "section-module--no-padding-bottom--2kpbA";
export var noPaddingTop = "section-module--no-padding-top---34ZB";
export var primary = "section-module--primary---mFBS";
export var right = "section-module--right--fkJAX";
export var secondary = "section-module--secondary--MGSOe";
export var sm = "section-module--sm--L6hjM";
export var transparent = "section-module--transparent--G9a3q";
export var white = "section-module--white--9QgSN";