import { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { useLocale, getProperty } from "@gh/shared";

export const usePages = () => {
  const refPages = useRef(null);
  const refLocale = useRef(null);
  const { locale: currentLocale } = useLocale();
  const key = "allMarkdownRemark.edges";
  const results = useStaticQuery(query) || {};
  const edges = getProperty(results, key) || [];

  if (refPages.current === null || refLocale.current !== currentLocale) {
    refPages.current = edges
      .map((edge) => getProperty(edge, "node.frontmatter"))
      .filter(({ locale }) => currentLocale === locale);
  }

  refLocale.current = currentLocale;

  return refPages.current;
};

export const usePagesByMenu = (value) =>
  usePages().filter((page) => {
    const menu = getProperty(page, "menu") || [];
    return menu.includes(value);
  });

export const usePageByView = (value) =>
  usePages().find((page) => getProperty(page, "view") === value);

const query = graphql`
  query usePagesQuery {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "page" } } }
      sort: { fields: [frontmatter___rank], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            path
            view
            menu
            title
            locale
          }
        }
      }
    }
  }
`;
