// extracted by mini-css-extract-plugin
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var fromLg = "container-module--from-lg--fN1l1";
export var fromMd = "container-module--from-md--QgI0+";
export var fromSm = "container-module--from-sm--PzCHi";
export var fromXs = "container-module--from-xs--lQIK4";
export var initial = "container-module--initial--vmKYt";
export var toLg = "container-module--to-lg--DUZlj";
export var toMd = "container-module--to-md--zPGrL";
export var toSm = "container-module--to-sm--7ybNI";
export var toXs = "container-module--to-xs--lFleG";