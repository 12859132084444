// extracted by mini-css-extract-plugin
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var error = "field-module--error--6hpXE";
export var input = "field-module--input--A-iMt";
export var label = "field-module--label--rgR6d";
export var message = "field-module--message--5uU3F";
export var required = "field-module--required--DWcxL";
export var submitted = "field-module--submitted--w++uE";
export var text = "field-module--text--6iSC8";
export var touched = "field-module--touched--d52Fs";
export var wrapper = "field-module--wrapper--s7nGQ";