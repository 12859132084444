// extracted by mini-css-extract-plugin
export var active = "expandable-module--active--InUNI";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var button = "expandable-module--button--gdcYN";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var content = "expandable-module--content--NhVbp";
export var icon = "expandable-module--icon--pXxnm";
export var label = "expandable-module--label--Gqqy0";
export var wrapper = "expandable-module--wrapper--8vug9";