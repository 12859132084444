// extracted by mini-css-extract-plugin
export var arrow = "button-module--arrow--aQmOD";
export var arrows = "button-module--arrows--9wtnq";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var icons = "button-module--icons--e3tXV";
export var separator = "button-module--separator--5E-hm";
export var separatorLeft = "button-module--separatorLeft--pg-xk";
export var separatorRight = "button-module--separatorRight--QFQSZ";
export var star = "button-module--star--WlWQG";
export var stars = "button-module--stars--HUxTn";
export var text = "button-module--text--VSSqd";
export var theme_arrow_back = "button-module--theme_arrow_back--WPVq3";
export var theme_flat = "button-module--theme_flat--gOJ+o";
export var wrapper = "button-module--wrapper--9r4xk";