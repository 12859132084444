// extracted by mini-css-extract-plugin
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var button = "modal-module--button--LKLOL";
export var close = "modal-module--close--Z+9Ue";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var container = "modal-module--container--ehU-U";
export var content = "modal-module--content--WO9O7";
export var scroller = "modal-module--scroller--1GTak";
export var svg = "modal-module--svg--fTDE-";
export var wrapper = "modal-module--wrapper--cLfyH";