// extracted by mini-css-extract-plugin
export var active = "overlay-module--active--IU7ur";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var item = "overlay-module--item--HG16t";
export var language = "overlay-module--language--1g4Xy";
export var languages = "overlay-module--languages--qpNu3";
export var logo = "overlay-module--logo--guJZ4";
export var wrapper = "overlay-module--wrapper--jgVS7";