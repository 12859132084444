// extracted by mini-css-extract-plugin
export var actions = "navbar-module--actions--1NbPP";
export var active = "navbar-module--active--uXcfa";
export var booking = "navbar-module--booking--Kwe7C";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var contact = "navbar-module--contact--Sg9Cw";
export var container = "navbar-module--container--Qd7ad";
export var icon = "navbar-module--icon--pYkDi";
export var item = "navbar-module--item--y5rI5";
export var items = "navbar-module--items--INIvh";
export var language = "navbar-module--language--tM8dl";
export var languages = "navbar-module--languages--nhFNY";
export var large = "navbar-module--large--reXyK";
export var left = "navbar-module--left--JGbzI";
export var logo = "navbar-module--logo--yGsty";
export var open = "navbar-module--open--ufKKU";
export var overlay = "navbar-module--overlay--mHBBX";
export var right = "navbar-module--right--HrRrz";
export var small = "navbar-module--small--V90jH";
export var svg = "navbar-module--svg--63Zo0";
export var toggle = "navbar-module--toggle--OJBM3";
export var wrapper = "navbar-module--wrapper--VJc1V";