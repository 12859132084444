// extracted by mini-css-extract-plugin
export var black = "title-module--black--C1E5h";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var error = "title-module--error--55ag2";
export var f1 = "title-module--f1--WZhVK";
export var f2 = "title-module--f2--kacns";
export var h1 = "title-module--h1--HvraU";
export var h2 = "title-module--h2--5iYLc";
export var h3 = "title-module--h3--tY5Kc";
export var h4 = "title-module--h4--L8AJq";
export var h5 = "title-module--h5--m+Tv+";
export var h6 = "title-module--h6--+iHU6";
export var lowercase = "title-module--lowercase--JEY0Y";
export var primary = "title-module--primary--+rN7Y";
export var s1 = "title-module--s1--tebHw";
export var s10 = "title-module--s10--X-vU0";
export var s2 = "title-module--s2--Bo5CF";
export var s3 = "title-module--s3--XlnPv";
export var s4 = "title-module--s4--AwYy0";
export var s5 = "title-module--s5--CMtAA";
export var s6 = "title-module--s6--0sjlG";
export var s7 = "title-module--s7--B8EMP";
export var s8 = "title-module--s8--Hz-9S";
export var s9 = "title-module--s9--F-9rf";
export var uppercase = "title-module--uppercase--19+3j";
export var w1 = "title-module--w1--c464X";
export var w2 = "title-module--w2--iN8Vl";
export var w3 = "title-module--w3--QY+oF";
export var w4 = "title-module--w4--PQoTa";
export var w5 = "title-module--w5--pfypc";
export var w6 = "title-module--w6--zjbs-";
export var w7 = "title-module--w7--idz14";
export var white = "title-module--white--zVaD3";