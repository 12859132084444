// extracted by mini-css-extract-plugin
export var body = "contact-module--body--io9cX";
export var bottom = "contact-module--bottom--d80dZ";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var close = "contact-module--close--5jnUI";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var contact = "contact-module--contact--wvpg+";
export var container = "contact-module--container--qHlWD";
export var content = "contact-module--content--cdakV";
export var direction = "contact-module--direction--RiCeQ";
export var entered = "contact-module--entered--VKAIQ";
export var entering = "contact-module--entering--FQe1A";
export var exited = "contact-module--exited--rKDw2";
export var exiting = "contact-module--exiting--HMe5t";
export var holder = "contact-module--holder--KMwsa";
export var map = "contact-module--map--22FHr";
export var request = "contact-module--request---NdP6";
export var scroller = "contact-module--scroller--gxMp6";
export var top = "contact-module--top--C53h9";
export var wrapper = "contact-module--wrapper--4qbe2";