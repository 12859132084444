// extracted by mini-css-extract-plugin
export var body = "popup-module--body--5klLE";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var container = "popup-module--container--VhiMt";
export var content = "popup-module--content--PGgfn";
export var entered = "popup-module--entered--3odA3";
export var entering = "popup-module--entering--dxzpy";
export var exited = "popup-module--exited--9YvSg";
export var exiting = "popup-module--exiting--+b9up";
export var header = "popup-module--header--u3LT-";
export var image = "popup-module--image--Y8RgU";
export var preEnter = "popup-module--preEnter--UE2Sz";
export var svg = "popup-module--svg--Sjrg6";
export var text = "popup-module--text--FlyHK";
export var wrapper = "popup-module--wrapper--n6OSs";