import { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { getProperty, useLocale } from "@gh/shared";

export const useActivities = () => {
  const refActivities = useRef(null);
  const refLocale = useRef(null);
  const { locale: currentLocale } = useLocale();
  const key = "allMarkdownRemark.edges";
  const results = useStaticQuery(query) || {};
  const edges = getProperty(results, key) || [];

  if (refActivities.current === null || refLocale.current !== currentLocale) {
    refActivities.current = {
      locale: currentLocale,
      activities: {
        items: edges
          .filter((edge) => currentLocale === getProperty(edge, "node.frontmatter.locale"))
          .map((edge) => ({
            ...getProperty(edge, "node.frontmatter.activity"),
            ...getProperty(edge, "node.frontmatter.meta"),
            path: getProperty(edge, "node.frontmatter.path"),
          })),
      },
    };
  }

  refLocale.current = currentLocale;

  return refActivities.current;
};

const query = graphql`
  query useActivitiesQuery {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          type: { eq: "page" },
          view: { eq: "activity" },
          activity: {
            hidden: { ne: true }
          }
        }
      }
      sort: {
        fields: frontmatter___activity___date, order: DESC
      }
    ) {
      edges {
        node {
          frontmatter {
            locale
            path
            meta {
              title
              description
            }
            activity {
              date
              highlight
              categories
              image {
                childImageSharp {
                  gatsbyImageData(width: 600, height: 400, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`;
