// extracted by mini-css-extract-plugin
export var acknowledgement = "top-module--acknowledgement--OPPPc";
export var background = "top-module--background--cE5Cq";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var contact = "top-module--contact--Wz1GV";
export var container = "top-module--container--NBHRP";
export var wrapper = "top-module--wrapper--T5xYs";